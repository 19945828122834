<template>
  <div>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-11">
              <h1><b-icon icon="clipboard-check" /> Programas de {{ name }}</h1>
            </div>
            <div class="col-1">
              <h1>
                <b-icon
                  type="button"
                  @click="toPeriods"
                  icon="arrow-return-left"
                />
              </h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="container">
            <b-row>
              <b-col><ProgramsRegister :id="id" :idNumber="idNumber" /></b-col>
              <b-col>
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input-group-prepend is-text>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="keyword"
                    placeholder="Buscar"
                    type="text"
                  /> </b-input-group
              ></b-col>
            </b-row>
          </div>
          <div class="container col-12">
            <div>
              <b-table
                striped
                responsive
                stacked="lg"
                :items="items"
                :fields="this.$checkRole('Administrador') ? fieldsAdmin : this.$checkRole('Finanzas') ? fieldsFinance : fields"
                :per-page="perPage"
                :current-page="currentPage"
                :keyword="keyword"
              >
                <template #cell(payments)="row">
                  <b-icon
                    type="button"
                    class="mx-1"
                    @click="toPayments(row.item.identifier)"
                    icon="cash-stack"
                  />
                </template>
                <template #cell(state)="row">
                  <b-badge pill :variant="returnColor(row.item.state)">{{
                    returnData(row.item.state)
                  }}</b-badge>
                </template>
                <template #cell(courses)="row">
                  <b-icon
                    type="button"
                    class="mx-1"
                    @click="toCourses(row.item.identifier)"
                    icon="card-checklist"
                  />
                  <span>{{ row.item.countCourses }}</span>
                </template>
                <template #cell(people)="row">
                  <b-icon
                    type="button"
                    @click="toPeople(row.item.identifier)"
                    icon="people"
                  />
                  <span> {{ row.item.enrollmentCount }}</span>
                </template>
                <template #cell(actions)="row">
                  <b-icon-pencil-square
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="dateItem(row.item)"
                  ></b-icon-pencil-square>

                  <b-icon-trash
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="modalDelete(row.item)"
                  ></b-icon-trash>
                </template>
              </b-table>
              <b-pagination
                style="display: flex; justify-content: center"
                v-model="currentPage"
                pills
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--modal eliminar-->
    <div>
      <b-modal centered v-model="showDelete" title="Mensaje">
        <b-container fluid>
          <!--Contenido modal-->
          <h5 class="text-center">
            ¿Seguro que desea eliminar a <b>{{ data.description }}</b
            >?
          </h5>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="deleteItem(dataDelete)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Aceptar
            </button>
            <button
              type="button"
              @click="exitDelete()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import ProgramsRegister from "./Programs-register.vue";
export default {
  components: { ProgramsRegister },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    totalPeople: 0,
    keyword: "",
    perPage: 10,
    currentPage: 1,
    name: "",
    idPeriod: "",
    idNumber: null,
    showDelete: false,
    data: {
      id: -1,
      index: "",
      description: "",
      dateOpening: "",
      dateClosing: "",
      state: "",
    },
    fields: [
      { key: "description", label: "Nombre descriptivo" },
      { key: "dateOpening", label: "Fecha Inicio" },
      { key: "dateClosing", label: "Fecha Fin" },
      { key: "people", label: "Personas" },
      { key: "courses", label: "Cursos" },
      { key: "state", label: "Estado" },
      { key: "actions", label: "Acciones" },
    ],
    fieldsAdmin: [
      { key: "description", label: "Nombre descriptivo" },
      { key: "dateOpening", label: "Fecha Inicio" },
      { key: "dateClosing", label: "Fecha Fin" },
      { key: "people", label: "Personas" },
      { key: "payments", label: "Pagos" },
      { key: "courses", label: "Cursos" },
      { key: "state", label: "Estado" },
      { key: "actions", label: "Acciones" },
    ],
    fieldsFinance: [
      { key: "description", label: "Nombre descriptivo" },
      { key: "people", label: "Personas" },
      { key: "payments", label: "Pagos" },
      { key: "state", label: "Estado" },
    ],
    desserts: [],
  }),

  created() {
    this.initialize();
  },
  computed: {
    rows() {
      return this.desserts.length;
    },
    items() {
      this.currentPage = 1;
      return this.keyword
        ? this.desserts.filter((item) =>
            item.identifier.toLowerCase().includes(this.keyword.toLowerCase())
          )
        : this.desserts;
    },
  },

  methods: {
    modalDelete(data) {
      this.showDelete = true;
      this.dataDelete = data;
      this.data.description = data.description;
    },
    exitDelete() {
      this.showDelete = false;
      this.data.description = "";
    },
    toPeople(data) {
      this.$router.push(
        "/academic/" + this.idPeriod + "/" + data + "/enrolleds"
      );
    },
    toPayments(data) {
      this.$router.push("/" + this.id + "/" + data +"/payments");
    },
    toCourses(data) {
      this.$router.push("/" + this.id + "/" + data + "/courses");
    },
    toPeriods() {
      this.$router.push("/academic");
    },
    returnData(data) {
      if (data === "A") return "Activo";
      else if (data === "I") return "Inactivo";
    },
    returnColor(data) {
      if (data === "A") return "success";
      else if (data === "I") return "danger";
    },
    async initialize() {
      let res = await this.$axios
        .get("/academic/" + this.id + "/programs", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
      var aux = [];
      this.name = res.data.name;
      this.idNumber = res.data.id.toString();
      this.idPeriod = res.data.identifier;
      var cont = 0;
      for (var i in res.data.programPeriods) {
        cont = cont + res.data.programPeriods[i].enrollmentCount;
        aux.push(res.data.programPeriods[i]);
      }
      this.desserts = aux;
      this.fields[3].label = "Personas (" + cont + ")";
      this.fieldsFinance[1].label = "Personas (" + cont + ")";
    },
    async deleteItem(item) {
      this.showDelete = false;
      if (item != "") {
        try {
          await this.$axios
            .get(
              "/academic/" + this.id + "/program-delete?programId=" + item.id,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.token}`,
                },
              }
            )
            .then((res) => {
              this.$message.success(res.data.content);
              this.initialize();
            });
        } catch (e) {
          this.$message.error("Error!");
          console.log(e);
        }
      }
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
</style>