<template>
  <div id="ProgramsRegister">
    <button
      @click="addRol()"
      style="background-color: #1d425e; color: white"
      class="btn mb-3"
    >
      Agregar Programa
    </button>
    <div>
      <b-modal scrollable centered v-model="show" title="Registra Programa">
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <AlertError ref="error" />
            <div class="form-group">
              <label for="exampleInputEmail1">Opciones:</label>
              <b-form-select
                class="form-control"
                v-model="opcion"
                :options="opciones"
                required
              ></b-form-select>
            </div>
            <div v-if="opcion != '0'">
              <div v-if="opcion == '1'" class="form-group">
                <label>Seleccionar programa</label>
                <b-form-select
                  class="form-control"
                  v-model="data.idProgram"
                  :options="programs"
                  required
                ></b-form-select>
              </div>
              <div v-if="opcion == '2'">
                <b-row>
                  <div class="form-group col-8">
                    <label>Nombre</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Ingrese su nombre"
                      v-model="dataExtra.name"
                    />
                  </div>
                  <div class="form-group col-4">
                    <label>Acrónimo</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      v-model="dataExtra.acronym"
                      placeholder="Ingrese"
                    />
                  </div>
                </b-row>
                <div class="form-group">
                  <label for="exampleInputEmail1">Área</label>
                  <b-form-select
                    class="form-control"
                    v-model="dataExtra.area"
                    :options="areas"
                  ></b-form-select>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Descripción</label>
                  <b-form-input
                    type="text"
                    class="form-control"
                    v-model="dataExtra.description"
                    placeholder="Ingrese descripción"
                  />
                </div>
              </div>
              <div class="form-group">
                <label>Fecha de inicio</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="data.dateOpening"
                  required
                />
              </div>
              <div class="form-group">
                <label>Cantidad de semanas</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="data.weeks"
                  required
                />
              </div>
              <div class="form-group">
                <label>Fecha de cierre de matricula</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="data.dateClosingEnrollmet"
                  required
                />
              </div>
              <div class="form-group">
                <label>Monto matricula</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="data.payEnrollmet"
                  placeholder="Ingrese monto de dinero"
                  required
                />
              </div>
              <div class="form-group">
                <label>Monto pensión</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="data.payPension"
                  placeholder="Ingrese monto de dinero"
                  required
                />
              </div>
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveAddItem(data)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AlertError from "../../components/AlertError.vue";
export default {
  name: "ProgramsRegister",
  components: { AlertError },
  props: {
    id: {
      type: String,
      default: null,
    },
    idNumber: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      opcion: "0",
      data: {},
      dataExtra: {},
      areas: [
        { text: "Área Técnica", value: "T" },
        { text: "Área Administrativa", value: "A" },
      ],
      opciones: [
        { text: "-- Seleccionar opción --", value: "0" },
        { text: "Seleccionar programa", value: "1" },
        { text: "Registrar programa", value: "2" },
      ],
      programs: [],
    };
  },
  methods: {
    exit() {
      this.show = false;
    },
    addRol() {
      this.opcion = "0";
      this.listPrograms();
      this.data = {
        idPeriod: null,
        idProgram: null,
        dateOpening: "",
        dateClosingEnrollmet: "",
        weeks: null,
        payEnrollmet: null,
        payPension: null,
        //ojo
        weeks: 0,
      };
      this.dataExtra = {
        name: "",
        acronym: "",
        description: "",
        area: "T",
      };
      this.show = true;
    },
    async listPrograms() {
      await this.$axios
        .get("/academic/" + this.id + "/program-register", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.programs = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async saveAddItem() {
      this.data.idPeriod = parseInt(this.idNumber);
      if (this.opcion == 1) {
        await this.$axios
          .post(
            "/academic/" + this.id + "/program-register-select",
            this.data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            }
          )
          .then((res) => {
            this.show = false;
            this.$message.success(res.data.content);
            this.$parent.initialize();
          })
          .catch((e) => {
            console.log(e);
            this.$refs.error.showAlert(e.response.data.content);
          });
      }
      if (this.opcion == 2) {
        delete this.data.idProgram;
        var newObject = Object.assign({}, this.data, this.dataExtra);
        await this.$axios
          .post("/academic/" + this.id + "/program-register-new", newObject, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          })
          .then((res) => {
            this.show = false;
            this.$message.success(res.data.content);
            this.$parent.initialize();
          })
          .catch((e) => {
            console.log(e);
            this.$refs.error.showAlert(e.response.data.content);
          });
      }
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>